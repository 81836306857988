<template>
    <v-container>
      <v-row>
        <v-col cols="12" lg="5">
          <s-crud
            title="Usuarios"
            search-input
            :config="configUser"
           
            @rowSelected="rowSelected($event)"
          >
          </s-crud>
        </v-col>
        
        <v-col v-if="item">
          <s-toolbar label="Configuración IP" >
            <v-text-field lg="5" 
                    v-model="searchText"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                ></v-text-field>
                  <v-btn icon small  @click="deleteItem">
                <v-icon>mdi-delete</v-icon>
                </v-btn>
          </s-toolbar>
                        
          <v-card elevation="0">
            <v-col>
              <v-row justify="center">
                <v-col lg="10" class="pb-1" cols="12">
                    <s-text
                        label="Dirección IP:"
                        v-model="IpName"
                    ></s-text>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col class="pt-1" lg="5"><v-btn @click="IpName = ''; $refs.usrcce.focus();" block small>Nuevo</v-btn></v-col>
                <v-col class="pt-1" lg="5"><v-btn @click="save(item)" block small color="primary">Guardar</v-btn></v-col>
              </v-row>
            </v-col>
          </v-card>
  
          <v-data-table
            v-if="Array.isArray(filteredItemsIps)"
            :items="filteredItemsIps"
            dense
            item-key="UicID"
            :headers="config.headers"
            v-model="selectedIps"
            :single-select="true"
            @click:row="rowClick"
            >
          </v-data-table>
          
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import _sPermit from "@/services/Logistics/PermitService";
  import _sUser from "@/services/Logistics/UserService";
  import sUser from "@/views/Security/UserOption/User.vue";
  import sAgrment from "@/services/HumanResource/HmnContractService";
  
  export default {
    components: { sUser },
    data: () => ({
      itemsUsers: [],
      itemsIps: [],
      IpName:'',
      item: null,
      UicID:0,
      selectedIps: [],
      userSelected: {},
      searchText: '',
      config: {
        model: { UicID: "ID", UsrID: "int" },
        headers: [
          { text: "ID", value: "UicID" },
          { text: "IP", value: "IpName" },
          { text: "Fecha", value: "SecCreate" },
        ],
        service: sAgrment,
      },
      configUser: {
        model: { UsrID: "ID" },
        headers: [
          { text: "ID", value: "UsrID" },
          { text: "Nombre", value: "NtpFullName" },
          { text: "Usuario", value: "UsrName" },
        ],
        service: _sUser,
      },
    }),
    computed: {
        filteredItemsIps() {
            if (!Array.isArray(this.itemsIps)) {
            return [];
            }
            if (!this.searchText) {
            return this.itemsIps;
            }
            return this.itemsIps.filter((item) =>
            item.IpName.toLowerCase().includes(this.searchText.toLowerCase())
            );
        },
    },
    methods: {
      rowClick(item) {
          console.log("itemdat", item);
          this.IpName=item.IpName;
          this.item.UicID=item.UicID;
          this.item.UsrID=item.UsrID;
          this.item.UsrUpdateID = this.$fun.getUserID();
      },
    //   saveCopyPermit() {
    //     this.usersCopy = this.usersCopy.filter(x => x.UsrID !== this.userSelected.UsrID);
    //     _sPermit.copyallPermit(this.userSelected, this.usersCopy, this.$fun.getUserID()).then(() => {
    //       this.$fun.alert("Permisos actualizados correctamente.", "success");
    //       this.closeDialogCopy();
    //     });
    //   },
      save(item) {
        if (!this.IpName) {
          this.$fun.alert("Falta la dirección IP", "warning");
          return;
        }
        if (!item.UsrID) {
          this.$fun.alert("Falta seleccionar un usuario", "warning");
          return;
        }
         item.IpName=this.IpName;
         item.SecStatus = 1;
         item.UsrCreateID = this.$fun.getUserID();
        sAgrment.saveUserIp(item, this.$fun.getUserID()).then((r) => {
            if (r.status == 200) {
                this.IpName = '';
                sAgrment.IPListByUsrID(item.UsrID, this.$fun.getUserID()).then((r) => {
                this.itemsIps = Array.isArray(r.data) ? r.data : [r.data]; 
                this.$fun.alert("Registro Exitosa", "success");
              });
            }
        });
      },
      deleteItem() {
          this.item.IpName=this.IpName;
          this.item.SecStatus = 0;
          this.item.UsrUpdateID = this.$fun.getUserID();
          sAgrment.saveUserIp(this.item, this.$fun.getUserID()).then((r) => {
            if (r.status == 200) {
                this.IpName = '';
                this.itemsIps = []; 
                this.$fun.alert("ELiminación Exitosa", "success");
            }
        });
      },
      rowSelected(items) {
        this.IpName = '';
        if (items.length > 0) {
          this.item = items[0];
          this.userSelected = items[0];
          this.itemsIps = [];
          this.item.UicID=0;
          console.log("usrID", this.item.UsrID);
          sAgrment.IPListByUsrID(this.item.UsrID, this.$fun.getUserID()).then((r) => {
            if(r.data!==null) {
                this.itemsIps = Array.isArray(r.data) ? r.data : [r.data]; 
            }  

          });
        } else {
          this.item = null;
          this.itemsIps = [];
        }
      },
    },
    mounted() {
    },
  };
  </script>

  